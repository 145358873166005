import React from "react";
import { SEO, PageLayout, HeroElement, Content } from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"About"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[fishermanBusiness.ctas.phone]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <Content container={true}>
                <Content.Markup isMarkdown={false}>
                  <Header as={"h1"} content={"About"} />
                  <p>
                    I am Kathryn Miles, chef and owner of Kathryn’s Soul. My
                    love for cooking and baking started at the very young age of
                    10. My father noticed that I had a gift for cooking, so he
                    began to encourage it. He bought my first cook book, Better
                    Homes and Gardens the New Cook Book. I cooked almost very
                    recipe in that book and then repeated my favorites until I
                    mastered them. Through the years, as I continued my
                    education, I continued to cook for my family and friends.
                  </p>
                  <p>
                    I have always been independent and started working full time
                    as a teenager. I worked in the corporate world for almost 20
                    years. However, food has always been my passion and through
                    the years I dreamed of making it a career, but I never had
                    the courage. In 2019, I decided to take a chance and pursue
                    my passion. First, I had to come up with a name. So many
                    people may think Kathryn’s Soul comes from my name and my
                    style of cooking, but it has a deeper meaning. I decided to
                    honor my mother, who I was named after. She lost her battle
                    with lung cancer before she was able to see this dream
                    become a reality, but I know she’s with me on this journey.
                  </p>
                  <p>
                    Then, I began catering on the side and it became a sensation
                    right out of the gate. The support from the community was
                    overwhelming and my loyal customers have been the key to my
                    success. Then 2020 came and the Pandemic allowed me the time
                    to make my passion a full-time career, and I did just that.
                    Kathryn’s Soul, a restaurant that offers quality comfort
                    food made with love was born.
                  </p>
                </Content.Markup>
              </Content>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          text
          link
          internal
        }
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "About" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
